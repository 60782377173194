/* tslint:disable:max-line-length */
import {TreoNavigationItem} from '@treo/components/navigation';
const permissions: string[] = JSON.parse(localStorage.getItem('permissions')) ?? [];
export const defaultNavigation: TreoNavigationItem[] = [
  {
    id: 'modules',
    title: 'Modules',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'modules.product-development',
        title: 'Product Development',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.product-development.product-master-list',
            title: 'Product Master Lists',
            type: 'basic',
          },
          {
            id: 'applications.product-development.installation-master-list',
            title: 'Installation Master Lists',
            type: 'basic',
          },
          {
            id: 'applications.product-development.maintenance-master-list',
            title: 'Maintenance Master Lists',
            type: 'basic',
          },
          {
            id: 'applications.product-development.material',
            title: 'Material',
            type: 'basic',
          },
          {
            id: 'applications.product-development.work-processes',
            title: 'Work Processes',
            type: 'basic',
          },
          {
            id: 'applications.product-development.tool-kit-list',
            title: 'Tool Kit List',
            type: 'basic',
          },
          {
            id: 'applications.product-development.equipment',
            title: 'Equipments',
            type: 'basic',
          },
        ],
      },
      {
        id: 'modules.sales-and-crm',
        title: 'Sales & CRM',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.sales-and-crm.clients',
            title: 'Customer Database',
            type: 'basic',
            link: '/clients',
          },
          {
            id: 'applications.sales-and-crm.quotation',
            title: 'Quotation',
            type: 'basic',
          },
          {
            id: 'applications.sales-and-crm.work-order',
            title: 'Work Order',
            type: 'basic',
          },
          {
            id: 'applications.sales-and-crm.loan-voucher',
            title: 'Loan Voucher',
            type: 'basic',
          },
          {
            id: 'applications.sales-and-crm.delivery-order',
            title: 'Delivery Order',
            type: 'basic',
          },
          {
            id: 'applications.sales-and-crm.contacts',
            title: 'Contacts',
            type: 'basic',
            link: '/contacts',
          },
        ],
      },
      {
        id: 'modules.projects-and-ops',
        title: 'Project & Ops',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.projects-and-ops.work-order',
            title: 'Work Order',
            type: 'basic',
          },
          {
            id: 'applications.projects-and-ops.work-order-scheduling',
            title: 'Work Order Scheduling',
            type: 'basic',
          },
          {
            id: 'applications.projects-and-ops.report-generation',
            title: 'Report Generation',
            type: 'basic',
          },
        ],
      },
      {
        id: 'modules.aftersales',
        title: 'Aftersales',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.aftersales.installation-record',
            title: 'Installation Record',
            type: 'basic',
          },
          {
            id: 'applications.aftersales.maintenance-packages',
            title: 'Maintenance Packages',
            type: 'basic',
            link: '/aftersales/maintenance-packages',
          },
          {
            id: 'modules.aftersales.register',
            title: 'Registers',
            type: 'collapsable',
            children: [
              {
                id: 'applications.aftersales.register.materials',
                title: 'Materials',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.register.work-process',
                title: 'Work Process',
                type: 'basic',
                link: '/work-processes',
              },
              {
                id: 'applications.aftersales.register.tool-kit',
                title: 'Tool Kit Register',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.register.equipment',
                title: 'Equipment',
                type: 'basic',
              },
            ],
          },
          {
            id: 'modules.aftersales.order-creation',
            title: 'Order Creation',
            type: 'collapsable',
            children: [
              {
                id: 'applications.aftersales.order-creation.quotation',
                title: 'Quotation',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.order-creation.service-contract',
                title: 'Service Contract',
                type: 'basic',
                link: '/aftersales-service-contracts',
              },
              {
                id: 'applications.aftersales.order-creation.service-order',
                title: 'Service Order',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.order-creation.delivery-order',
                title: 'Delivery Order',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.order-creation.loan-voucher',
                title: 'Loan Voucher',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.order-creation.permit-to-work',
                title: 'Permit to Work',
                type: 'basic',
              },
            ],
          },
          {
            id: 'modules.aftersales.service-scheduling',
            title: 'Service Scheduling',
            type: 'basic',
            link: '/aftersales/service-scheduling',
          },
          {
            id: 'modules.aftersales.report-generation',
            title: 'Report Generation',
            type: 'collapsable',
            children: [
              {
                id: 'applications.aftersales.report-generation.contract',
                title: 'Contracts',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.report-generation.quotation',
                title: 'Quotations',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.report-generation.service-order-summary-period',
                title: 'Service Order Summary (Period)',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.report-generation.service-order-summary-individual',
                title: 'Service Order Summary (Ind)',
                type: 'basic',
              },
              {
                id: 'applications.aftersales.report-generation.equipment-logbook',
                title: 'Equipment Logbook',
                type: 'basic',
              },
            ],
          },
        ],
      },
      {
        id: 'modules.business-ops',
        title: 'Biz Ops',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.business-ops.create-delivery-order',
            title: 'Delivery Order',
            type: 'basic',
          },
        ],
      },
      {
        id: 'modules.hr',
        title: 'HR',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          permissions.includes('view-user') && {
            id: 'applications.hr.employee',
            title: 'Employee',
            type: 'basic',
            link: '/employees',
          },
          {
            id: 'modules.hr.reporting',
            title: 'Reporting',
            type: 'collapsable',
            children: [
              {
                id: 'applications.hr.reporting.manpower-movement',
                title: 'Manpower Movement',
                type: 'basic',
              },
            ],
          },
        ],
      },
      {
        id: 'modules.hse-and-bcp',
        title: 'HSE & BCP',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.hse-and-bcp.register',
            title: 'Installation / Service Packages',
            type: 'collapsable',
            children: [
              {
                id: 'applications.hse-and-bcp.register.risk',
                title: 'Risk',
                type: 'basic',
              },
              {
                id: 'applications.hse-and-bcp.register.control',
                title: 'Control',
                type: 'basic',
              },
              {
                id: 'applications.hse-and-bcp.register.additional-control-measure',
                title: 'Additional Control Measure',
                type: 'basic',
              },
              {
                id: 'applications.hse-and-bcp.register.ppe',
                title: 'PPE',
                type: 'basic',
              },
              {
                id: 'applications.hse-and-bcp.register.swp',
                title: 'SWP',
                type: 'basic',
              },
            ],
          },
          {
            id: 'applications.hse-and-bcp.permit-to-work',
            title: 'Permit to Work',
            type: 'basic',
          },
        ],
      },
      {
        id: 'modules.app-admin',
        title: 'App Administrator',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
          {
            id: 'applications.app-admin.access-right',
            title: 'Access Rights',
            type: 'basic',
            link: '/admin/roles',
          },
          {
            id: 'applications.app-admin.data-fields',
            title: 'Data Fields',
            type: 'basic',
            link: '/reference-types',
          },
        ],
      },
    ],
  },
];

export const compactNavigation: TreoNavigationItem[] = [
  {
    id: 'applications',
    title: 'Apps',
    type: 'aside',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const futuristicNavigation: TreoNavigationItem[] = [
  {
    id: 'apps',
    title: 'APPS',
    type: 'group',
    children: [
      {
        id: 'applications.dashboards.finance',
        title: 'Finance',
        type: 'basic',
        icon: 'heroicons_outline:cash',
        link: '/dashboards/finance',
      },
      {
        id: 'applications.dashboards.analytics',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboards/analytics',
      },
      {
        id: 'applications.dashboards.crypto',
        title: 'Crypto',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/dashboards/crypto',
      },
      {
        id: 'applications.calendar',
        title: 'Calendar',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/apps/calendar',
      },
      {
        id: 'applications.contacts',
        title: 'Contacts',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/apps/contacts',
      },
      {
        id: 'applications.ecommerce',
        title: 'ECommerce',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
          {
            id: 'applications.ecommerce.inventory',
            title: 'Inventory',
            type: 'basic',
            link: '/apps/ecommerce/inventory',
          },
        ],
      },
      {
        id: 'applications.mailbox',
        title: 'Mailbox',
        type: 'basic',
        icon: 'heroicons_outline:mail',
        link: '/apps/mailbox',
        badge: {
          title: '27',
          style: 'rounded',
          background: 'rgba(0, 0, 0, 0.24)',
          color: '#FFFFFF',
        },
      },
      {
        id: 'applications.tasks',
        title: 'Tasks',
        type: 'basic',
        link: '/apps/tasks',
      },
    ],
  },
  {
    id: 'others',
    title: 'OTHERS',
    type: 'group',
  },
];
export const horizontalNavigation: TreoNavigationItem[] = [
  {
    id: 'applications',
    title: 'Apps',
    type: 'group',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
