import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {EmptyLayoutComponent} from 'app/layout/layouts/empty/empty.component';
import {InitialDataResolver} from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboards/finance'
  {path: '', pathMatch: 'full', redirectTo: 'projects'},

  // Redirect signed in user to the '/dashboards/finance'
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'projects',
  },

  // Auth routes
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            (m) => m.AuthConfirmationRequiredModule,
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(
            (m) => m.AuthForgotPasswordModule,
          ),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule,
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule,
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      {
        path: 'dashboards',
        children: [
          {
            path: 'finance',
            loadChildren: () =>
              import('app/modules/admin/dashboards/finance/finance.module').then(
                (m) => m.FinanceModule,
              ),
          },
          {
            path: 'analytics',
            loadChildren: () =>
              import('app/modules/admin/dashboards/analytics/analytics.module').then(
                (m) => m.AnalyticsModule,
              ),
          },
          {
            path: 'crypto',
            loadChildren: () =>
              import('app/modules/admin/dashboards/crypto/crypto.module').then(
                (m) => m.CryptoModule,
              ),
          },
        ],
      },

      // Modules
      {
        path: '',
        children: [
          {
            path: 'employees',
            loadChildren: () =>
              import('app/modules/employee/employee.module').then((m) => m.EmployeeModule),
          },
          {
            path: 'clients',
            loadChildren: () =>
              import('app/modules/client/client.module').then((m) => m.ClientModule),
          },
          {
            path: 'contacts',
            loadChildren: () =>
              import('app/modules/contact/contact.module').then((m) => m.ContactModule),
          },
          {
            path: 'work-processes',
            loadChildren: () =>
              import('app/modules/work-process/work-process.module').then(
                (m) => m.WorkProcessModule,
              ),
          },
          {
            path: 'reference-types',
            loadChildren: () =>
              import('app/modules/reference-type/reference-type.module').then(
                (m) => m.ReferenceTypeModule,
              ),
          },
          {
            path: 'aftersales/maintenance-packages',
            loadChildren: () =>
              import('app/modules/service-package/service-package.module').then(
                (m) => m.ServicePackageModule,
              ),
          },
          {
            path: 'aftersales-service-contracts',
            loadChildren: () =>
              import('app/modules/project/project.module').then((m) => m.ProjectModule),
          },
          {
            path: 'admin/roles',
            loadChildren: () => import('app/modules/role/role.module').then((m) => m.RoleModule),
          },
          {
            path: 'permissions',
            loadChildren: () =>
              import('app/modules/permission/permission.module').then((m) => m.PermissionModule),
          },
          {
            path: 'aftersales/service-scheduling',
            loadChildren: () =>
              import(
                'app/modules/aftersales-service-scheduling/aftersales-service-scheduling.module'
              ).then((m) => m.AftersalesServiceSchedulingModule),
          },
        ],
      },

      // Pages
      {
        path: 'pages',
        children: [
          // Errors
          {
            path: 'errors',
            children: [
              {
                path: '404',
                loadChildren: () =>
                  import('app/modules/admin/pages/errors/error-404/error-404.module').then(
                    (m) => m.Error404Module,
                  ),
              },
              {
                path: '500',
                loadChildren: () =>
                  import('app/modules/admin/pages/errors/error-500/error-500.module').then(
                    (m) => m.Error500Module,
                  ),
              },
            ],
          },

          // Maintenance
          {
            path: 'maintenance',
            loadChildren: () =>
              import('app/modules/admin/pages/maintenance/maintenance.module').then(
                (m) => m.MaintenanceModule,
              ),
          },
        ],
      },

      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
          import('app/modules/admin/pages/errors/error-404/error-404.module').then(
            (m) => m.Error404Module,
          ),
      },
      {path: '**', redirectTo: '404-not-found'},
    ],
  },
];
