import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '../environments/environment';
import { onError } from "@apollo/client/link/error";


const uri = environment.graphqlUrl;
export const createApollo = (httpLink: HttpLink) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)

      graphQLErrors.map(({ message, locations, path }) => { throw new Error(`${message}\n\nPath: ${path}`); }
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  return {
    link: errorLink.concat(httpLink.create({ uri })),
    cache: new InMemoryCache(),
  };
};

@NgModule({
  exports: [],

  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
