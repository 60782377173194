import {maintenanceRoutes} from 'app/modules/admin/pages/maintenance/maintenance.routing';
import {AuthMockApi} from 'app/data/mock/auth';
import {CalendarMockApi} from 'app/data/mock/apps/calendar';
import {ContactsMockApi} from 'app/data/mock/apps/contacts';
import {AnalyticsMockApi} from 'app/data/mock/dashboards/analytics';
import {CryptoMockApi} from 'app/data/mock/dashboards/crypto';
import {ECommerceInventoryMockApi} from 'app/data/mock/apps/ecommerce/inventory';
import {FinanceMockApi} from 'app/data/mock/dashboards/finance';
import {HelpCenterMockApi} from 'app/data/mock/pages/help-center';
import {IconsMockApi} from 'app/data/mock/ui/icons';
import {MailboxMockApi} from 'app/data/mock/apps/mailbox';
import {MessagesMockApi} from 'app/data/mock/common/messages';
import {NavigationMockApi} from 'app/data/mock/common/navigation';
import {NotificationsMockApi} from 'app/data/mock/common/notifications';
import {SearchMockApi} from 'app/data/mock/common/search';
import {ShortcutsMockApi} from 'app/data/mock/common/shortcuts';
import {DeliveryOrdersMockApi} from 'app/data/mock/apps/services/delivery-order';
import {LoanVouchersMockApi} from 'app/data/mock/apps/services/loan-voucher';
import {TaxInvoiceMockApi} from 'app/data/mock/apps/services/tax-invoice';
import {ProjectHandoversMockApi} from 'app/data/mock/apps/services/project-handover';
import {SportTurfHandoversMockApi} from 'app/data/mock/apps/services/sport-turf-handover';
import {MaintenanceHandoversMockApi} from 'app/data/mock/apps/services/maintenance-handover';
import {UserMockApi} from 'app/data/mock/common/user';
import {ClientMockApi} from 'app/data/mock/apps/client';

export const mockDataServices = [
  AnalyticsMockApi,
  AuthMockApi,
  CalendarMockApi,
  ContactsMockApi,
  CryptoMockApi,
  ECommerceInventoryMockApi,
  FinanceMockApi,
  HelpCenterMockApi,
  IconsMockApi,
  MailboxMockApi,
  MessagesMockApi,
  NavigationMockApi,
  NotificationsMockApi,
  SearchMockApi,
  ShortcutsMockApi,
  DeliveryOrdersMockApi,
  LoanVouchersMockApi,
  TaxInvoiceMockApi,
  ProjectHandoversMockApi,
  SportTurfHandoversMockApi,
  MaintenanceHandoversMockApi,
  UserMockApi,
  ClientMockApi,
];
