import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {TreoMockApi} from '@treo/lib/mock-api/mock-api.interfaces';
import {TreoMockApiService} from '@treo/lib/mock-api/mock-api.service';
import {clientsData} from 'app/data/mock/apps/client/data';
import {TreoMockApiUtils} from '@treo/lib/mock-api/mock-api.utils';
import {cloneDeep, assign} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ClientMockApi implements TreoMockApi {
  private _clients: any[];

  /**
   * Constructor
   *
   * @param {TreoMockApiService} _treoMockApiService
   */
  constructor(private _treoMockApiService: TreoMockApiService) {
    // Set the data
    this._clients = clientsData;

    // Register the API endpoints
    this.register();
  }

  /**
   * Register
   */
  register(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Clients - GET
    // -----------------------------------------------------------------------------------------------------
    this._treoMockApiService.onGet('api/apps/clients/all').reply((request) => {
      // Get available queries
      const search = request.params.get('search');
      const sort = request.params.get('sort') || 'company';
      const order = request.params.get('order') || 'asc';
      const page = parseInt(request.params.get('page'), 10);
      const size = parseInt(request.params.get('size'), 10);

      // Clone the products
      let clients = _.cloneDeep(this._clients);

      // Sort the products
      if (sort === 'company' || sort === 'personInCharge' || sort === 'phone') {
        clients.sort((a, b) => {
          const fieldA = a[sort].toString().toUpperCase();
          const fieldB = b[sort].toString().toUpperCase();
          return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
        });
      } else {
        clients.sort((a, b) => (order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]));
      }

      // If search exists...
      if (search) {
        // Filter the products
        clients = clients.filter((client) => {
          return client.company && client.company.toLowerCase().includes(search.toLowerCase());
        });
      }

      // Paginate - Start
      const productsLength = clients.length;

      // Calculate pagination details
      const begin = page * size;
      const end = Math.min(size * (page + 1), productsLength);
      const lastPage = Math.max(Math.ceil(productsLength / size), 1);

      // Prepare the pagination object
      let pagination = {};

      // If the requested page number is bigger than
      // the last possible page number, return null for
      // products but also send the last possible page so
      // the app can navigate to there
      if (page > lastPage) {
        clients = null;
        pagination = {
          lastPage,
        };
      } else {
        // Paginate the results by size
        clients = clients.slice(begin, end);

        // Prepare the pagination data
        pagination = {
          totalCount: productsLength,
          perPage: size,
          currentPage: page + 1,
          totalPages: lastPage,
        };
      }
      // Paginate - End

      return [
        200,
        {
          clients,
          pagination,
        },
      ];
    });

    this._treoMockApiService.onGet('api/apps/clients').reply((request) => {
      const id = request.params.get('id');
      const clients = _.cloneDeep(this._clients);

      const client = clients.find((item) => {
        return item.id === id;
      });

      return [200, client];
    });

    this._treoMockApiService.onPut('api/apps/clients').reply(() => {
      const newClient = {
        id: TreoMockApiUtils.guid(),
        company: 'New Client',
        address: '',
        email: '',
        personInCharge: '',
        phone: '',
      };

      this._clients.unshift(newClient);

      return [200, newClient];
    });

    this._treoMockApiService.onPatch('api/apps/clients').reply((request) => {
      const id = request.body.id;
      const client = cloneDeep(request.body.client);

      let updatedClient = null;

      this._clients.forEach((item, index, clients) => {
        if (item.id === id) {
          clients[index] = assign({}, clients[index], client);
          updatedClient = clients[index];
        }
      });

      return [200, updatedClient];
    });

    this._treoMockApiService.onDelete('api/apps/clients').reply((request) => {
      const id = request.params.get('id');

      this._clients.forEach((item, index) => {
        if (item.id === id) {
          this._clients.splice(index, 1);
        }
      });

      return [200, true];
    });
  }
}
