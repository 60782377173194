export const clientsData = [
  {
    id: 'f65d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: 'b65d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'BCS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Mrs Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '18700439',
  },
  {
    id: 'c65d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'CCS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Dr Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '48700439',
  },
  {
    id: 'd65d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'DCS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Sir Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '28700439',
  },
  {
    id: 'e65d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ECS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '78700439',
  },
  {
    id: '265d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '165d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '465d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '365d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '565d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '665d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '765d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '965d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
  {
    id: '965d517a-6f69-4c88-81f5-416f47405ce1',
    company: 'ACS (Independant) Dover',
    address: '121 Dover Road, Singapore',
    personInCharge: 'Ms Ann Foo',
    email: 'annfooallian@gmail.com',
    phone: '68700439',
  },
];
