import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import moment from 'moment';
import {MatMomentDateModule} from '@angular/material-moment-adapter';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatMomentDateModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, MatMomentDateModule],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: moment.ISO_8601,
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class SharedModule {}
