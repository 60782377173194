import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TreoAnimations} from '@treo/animations';
import {TreoNavigationItem} from '@treo/components/navigation/navigation.types';
import {TreoNavigationService} from '@treo/components/navigation/navigation.service';

@Component({
  selector: 'treo-horizontal-navigation',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss'],
  animations: TreoAnimations,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'treoHorizontalNavigation',
})
export class TreoHorizontalNavigationComponent implements OnInit, OnDestroy {
  onRefreshed: BehaviorSubject<boolean | null>;

  // Name
  @Input()
  name: string;

  // Private
  private _navigation: TreoNavigationItem[];
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {TreoNavigationService} _treoNavigationService
   * @param {ChangeDetectorRef} _changeDetectorRef
   */
  constructor(
    private _treoNavigationService: TreoNavigationService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.onRefreshed = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for data
   */
  @Input()
  set navigation(value: TreoNavigationItem[]) {
    // Store the data
    this._navigation = value;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  get navigation(): TreoNavigationItem[] {
    return this._navigation;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Register the navigation component
    this._treoNavigationService.registerComponent(this.name, this);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Deregister the navigation component from the registry
    this._treoNavigationService.deregisterComponent(this.name);

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Refresh the component to apply the changes
   */
  refresh(): void {
    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Execute the observable
    this.onRefreshed.next(true);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
