import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PopupDialogComponent, PopupDialogData } from "app/layout/details/popup-dialog/popup-dialog.component";
import { LoggerService } from '../../shared/services/logger.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private matDialog: MatDialog,
    private loggerService: LoggerService,
    private zone: NgZone
  ) { }

  handleError(error) {
    this.zone.run(() => {
      if (error instanceof Error) {
        if (error instanceof TypeError) {
          return;
        }
        this.matDialog.open(PopupDialogComponent, {
          data: { message: `${error}` },
          panelClass: 'app-popup-dialog',
        });
        this.loggerService.error(error);
      }

    });

  }
}