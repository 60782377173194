import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

export interface ILoggerService {
  log(message?: any, ...optionalParams: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
}

@Injectable()
export class LoggerService implements ILoggerService {
  log(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
    }
  }

  error(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.error(message, ...optionalParams);
    }
  }
}
