import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Apollo } from 'apollo-angular';
import { LoginMutation, MeQuery, RefreshTokenMutation } from './auth.graphql';
import { User } from 'graphql/generated/graphql';

@Injectable()
export class AuthService {
  // Private
  private _authenticated: boolean;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private apollo: Apollo) {
    // Set the defaults
    this._authenticated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter and getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  set refreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  set userProfile(user: User) {
    localStorage.setItem('user_profile', JSON.stringify(user));
  }

  get userProfile(): User {
    return JSON.parse(localStorage.getItem('user_profile'));
  }

  set permissions(user: string[]) {
    localStorage.setItem('permissions', JSON.stringify(user));
  }

  get permissions(): string[] {
    return JSON.parse(localStorage.getItem('permissions'));
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }
    return this.apollo
      .query({
        query: LoginMutation,
        variables: {
          data: {
            username: credentials.email,
            password: credentials.password,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(async ({ data }: { data: any }) => {
          const auth = data?.login;
          this.accessToken = auth.accessToken;
          this.refreshToken = auth.refreshToken;
          await this.getMe();
          return auth;
        }),
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token

    return this.apollo
      .query({
        query: RefreshTokenMutation,
        variables: {
          token: this.refreshToken,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }: { data: any }) => {
          const auth = data?.refreshToken;
          this.accessToken = auth.accessToken;
          this.refreshToken = auth.refreshToken;
          // Set the authenticated flag to true
          this._authenticated = true;

          // Return true
          return of(true);
        }),
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  async getMe(): Promise<void> {
    // Renew token
    return await this.apollo
      .query({
        query: MeQuery,
        variables: {},
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }: { data: any }) => {
          const me = data?.me;
          this.userProfile = me;
          this.permissions = me.role.permissions.map((x) => x.slug);
        }),
      )
      .toPromise();
  }
}
