import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface PopupDialogData {
  message: string;
  confirm: boolean;
}
@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupDialogComponent implements OnInit {
  @Input()
  message: string;
  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupDialogData,
  ) {
    this.message = data.message;
  }
  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close({confirm: false});
  }

  onOkClick(): void {
    this.dialogRef.close({confirm: true});
  }
}
