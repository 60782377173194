import {gql} from 'apollo-angular';

export const RefreshTokenMutation = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      accessToken
      expiresIn
      refreshToken
      refreshExpiresIn
    }
  }
`;

export const LoginMutation = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      accessToken
      expiresIn
      refreshToken
      refreshExpiresIn
    }
  }
`;

export const MeQuery = gql`
  query me {
    me {
      email
      role {
        name
        permissions {
          slug
        }
      }
    }
  }
`;
